import React, { useState } from 'react'
import { useAccount, useNetwork } from 'wagmi'
import stakeABi from '../json/bscstake.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { BSC_CHAIN_ID, BSC_STAKE_APY, contract } from '../hooks/constant';
import { getContract } from '../hooks/contractHelper';
import { getWeb3 } from '../hooks/connectors';
import { useAdminStats } from '../hooks/useBscCommon';





export default function Admin() {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const signer = useEthersSigner();
    const [inputs, setInputs] = useState({
        withdraw_token_address: "",
        withdraw_token_amount: "",
        withdraw_token_decimals: 18,
        new_owner: "",
        stake_user_address: "",
        stake_amount: 0,
        stake_period: 0,
        stake_referrer: "0x0F9f63393e0F20057d1B07Aab618309D8Ac9A868"
    })
    const adminStats = useAdminStats(1);


    const handleChange = (e) => {
        setInputs({
            ...inputs,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = async (id = 0) => {
        if (address && id) {
            if (chain.id === BSC_CHAIN_ID) {
                try {
                    let tokenContract = getContract(stakeABi, contract[BSC_CHAIN_ID].STAKE_ADDRESS, signer);
                    let tx;
                    if (id === 1) {
                        tx = await tokenContract.withdrawBNB({ 'from': address });
                    }
                    else if (id === 2) {
                        let a = ethers.utils.parseUnits(inputs.withdraw_token_amount.toString(), inputs.withdraw_token_decimals);
                        tx = await tokenContract.withdrawErc20Token(inputs.withdraw_token_address, a, { 'from': address });
                    }
                    else if (id === 3) {
                        tx = await tokenContract.transferOwnership(inputs.new_owner, { 'from': address });
                    }
                    else if(id === 4){
                        console.log(inputs.stake_period.toString())
                        tx = await tokenContract.addStake(inputs.stake_user_address , ethers.utils.parseEther(inputs.stake_amount.toString()) , inputs.stake_period.toString() , inputs.stake_referrer)
                    }
                    else {
                        return false;
                    }



                    const resolveAfter3Sec = new Promise(resolve => setTimeout(resolve, 5000));
                    toast.promise(
                        resolveAfter3Sec,
                        {
                            pending: 'Waiting for confirmation',
                        }
                    )

                    var interval = setInterval(async function () {
                        let web3 = getWeb3(BSC_CHAIN_ID);
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.success('success ! your last transaction is success 👍');


                            }
                            else if (response.status === false) {
                                toast.error('error ! Your last transaction is failed.');


                            }
                            else {
                                toast.error('error ! something went wrong.');


                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.error(err.reason ? err.reason : err.message);

                }
            }
            else {
                toast.error('Please select Binance Mainnet Network !');

            }

        }
        else {
            toast.error('Please Connect Wallet!');

        }
    }


    return (
        <>
           <section className="top-padding">
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Input</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Withdraw BNB</td>
                            <td></td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(1)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>withdrawErc20Token</td>
                            <td>
                                <input value={inputs.withdraw_token_address} onChange={(e) => handleChange(e)} placeholder='Enter token address' type='text' name='withdraw_token_address' className='form-control my-1' />
                                <input value={inputs.withdraw_token_amount} onChange={(e) => handleChange(e)} placeholder='Enter token amount' type='text' name='withdraw_token_amount' className='form-control my-1' />
                                <input value={inputs.withdraw_token_decimals} onChange={(e) => handleChange(e)} placeholder='Enter token decimals' type='number' name='withdraw_token_decimals' className='form-control my-1' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(2)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Transfer Ownership</td>
                            <td>
                                <input value={inputs.new_owner} onChange={(e) => handleChange(e)} placeholder='Enter new  owner address' type='text' name='new_owner' className='form-control my-1' />
                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(3)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Custom Stake</td>
                            <td>
                                <input value={inputs.stake_user_address} onChange={(e) => handleChange(e)} placeholder='Enter user Address' type='text' name='stake_user_address' className='form-control my-1' />
                                <input value={inputs.stake_amount} onChange={(e) => handleChange(e)} placeholder='Enter amount' type='text' name='stake_amount' className='form-control my-1' />
                                <select onChange={(e) => handleChange(e)} name='stake_period' class="form-select" aria-label="Default select example">
                                    {adminStats.duration && adminStats.duration.map((items,key)=>(
                                        <option value={key} selected={inputs.stake_period === key}>{parseInt(items / 86400)} Days</option>
                                    ))}
                                    
                                </select>
                                <input value={inputs.stake_referrer} onChange={(e) => handleChange(e)} placeholder='Enter referral address' type='text' name='stake_referrer' className='form-control my-1' />

                            </td>
                            <td>
                                <button type='button' onClick={() => handleSubmit(4)} className='btn btn-success'>Submit</button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </section>
        </>
    )
}