import moment from "moment";

export const trimAddress = (addr) => {
  return `${addr.substring(0, 6)}...${addr.substring(addr.length - 4)}`;
}

export const contract = {
  97: {
    STAKE_ADDRESS: "0x59eF6efE076eD53C1eb375Bb6f22b271619dE121",
    MULTICALL_ADDRESS: "0xa54fe4a3dbd1eb21524cd73754666b7e13b4cb18"
  },
  56: {
    STAKE_ADDRESS: "0x9a4cb73763edd75ffa8cb41d8e597dc8a9cb8ed6",
    MULTICALL_ADDRESS: "0xed386fe855c1eff2f843b910923dd8846e45c5a4"
  }
}

export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const DEFAULT_RPC = "https://bsc-dataseed1.binance.org/"
// export const DEFAULT_RPC = "https://data-seed-prebsc-2-s2.bnbchain.org:8545"
export const BSC_CHAIN_ID = 56;
export const EXPLORE_URL = "https://bscscan.com"
export const INIT_USER = 180
export const INIT_AMOUNT = 8000
export const BSC_STAKE_APY =
  [
    {
      apy: "110",
      time: "30"
    },
    {
      apy: "125",
      time: "60 "
    },
    {
      apy: "200",
      time: "200"
    },
    {
      apy: "365",
      time: "365"
    }
  ]


export const formatDateWithZone = (unixTime, onlydate = false) => {
  try {
    if (onlydate) {
      let dateString = moment(unixTime).format("LL");
      return dateString;
    }
    else {
      let dateString = moment(unixTime).format('lll');
      return dateString;
    }
  }
  catch (err) {
    console.log(err.message);
  }

}
