import { useEffect, useState } from "react"
import bscstakeAbi from '../json/bscstake.json';
import tokenAbi from '../json/token.json';
import { contract } from "./constant";
import { MulticallContractWeb3 } from "./useContracts";
import { getWeb3 } from "./connectors";
import { BSC_CHAIN_ID } from "./constant";
import { toast } from "react-toastify";


export const useCommonStats = (updater) => {
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        totalClaimedRewardToken: 0,
        totalStakedToken: 0,
        totalStakers: 0,
        totalUnStakedToken: 0,
        rewardTokenAddress: '',
        stakeTokenAddress: '',
        rewardTokenSymbol: '',
        stakeTokenSymbol: '',
        rewardTokenDecimals: 0,
        stakeTokenDecimals: 0,
        lastWeekWinner: [],
        currentWeekTop: [],
        totalWeekInvestment: 0,
        totalchc: 0,
        chcPrice: 0,
        bonous : [],
        duration : []

    });

    const mc = MulticallContractWeb3();
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[BSC_CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    stakeContract.methods.totalClaimedRewardToken(), //0
                    stakeContract.methods.totalStakedToken(), //1
                    stakeContract.methods.totalStakers(), //2
                    stakeContract.methods.totalUnStakedToken(), //3
                    stakeContract.methods.rewardToken(), //4
                    stakeContract.methods.stakeToken(), //5
                    stakeContract.methods.weekCount(), //6
                    stakeContract.methods.WEEK_REWARD_PERCENTAGE(), //7
                    stakeContract.methods.getTokenPrice(), //8
                    stakeContract.methods.Bonus(0), //9
                    stakeContract.methods.Bonus(1), //10
                    stakeContract.methods.Bonus(2), //11
                    stakeContract.methods.Bonus(3), //12
                    stakeContract.methods.Duration(0), //13
                    stakeContract.methods.Duration(1), //14
                    stakeContract.methods.Duration(2), //15
                    stakeContract.methods.Duration(3), //16
                ]);



                let rewardTokenContract = new web3.eth.Contract(tokenAbi, data[4]);
                let stakeTokenContract = new web3.eth.Contract(tokenAbi, data[5]);

                const tokenData = await mc.aggregate([
                    rewardTokenContract.methods.symbol(), //0
                    rewardTokenContract.methods.decimals(), //1
                    stakeTokenContract.methods.symbol(), //2
                    stakeTokenContract.methods.decimals(), //3
                    stakeContract.methods.WeeklyTopUser(data[6]), //4
                    stakeContract.methods.WeeklyTopUser(data[6] - 1), //5
                    stakeContract.methods.totalWeekInvestmentchc(data[6]), //6
                ]);


                setStats({
                    totalClaimedRewardToken: data[0] / Math.pow(10, tokenData[1]),
                    totalStakedToken: data[1] / Math.pow(10, tokenData[3]),
                    totalStakers: data[2],
                    totalUnStakedToken: data[3] / Math.pow(10, tokenData[3]),
                    rewardTokenAddress: data[4],
                    stakeTokenAddress: data[5],
                    rewardTokenSymbol: tokenData[0],
                    stakeTokenSymbol: tokenData[2],
                    rewardTokenDecimals: tokenData[1],
                    stakeTokenDecimals: tokenData[3],
                    lastWeekWinner: tokenData[5] ? tokenData[5] : [],
                    currentWeekTop: tokenData[4] ? tokenData[4] : [],
                    totalchc: ((tokenData[6] / Math.pow(10, tokenData[1])) * (data[7] / 10)) / 100,
                    chcPrice: data[8] / Math.pow(10, 18),
                    duration : [data[13] , data[14], data[15] , data[16] ],
                    bonous : [data[9] , data[10], data[11] , parseInt(data[12]) + 300 ],
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}


export const useRefStats = () => {
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        l1: 0,
        l2: 0,
        l3: 0,
        l4: 0,
        l5: 0,

    });

    const mc = MulticallContractWeb3();
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[BSC_CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    stakeContract.methods.refLevelRate(0), //0
                    stakeContract.methods.refLevelRate(1), //0
                    stakeContract.methods.refLevelRate(2), //0
                    stakeContract.methods.refLevelRate(3), //0
                    stakeContract.methods.refLevelRate(4), //0
                ]);



                setStats({
                    l1: data[0] / 10,
                    l2: data[1] / 10,
                    l3: data[2] / 10,
                    l4: data[3] / 10,
                    l5: data[4] / 10,
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }

        if (mc) {
            fetch();
        }
        else {
            setStats({
                l1: 0,
                l2: 0,
                l3: 0,
                l4: 0,
                l5: 0,
            })
        }
        // eslint-disable-next-line
    }, []);

    return stats;
}



function getTop4Users(deposits) {
    // Sort deposits based on the amount (second element in each array) in descending order
    const sortedDeposits = deposits.slice().sort((a, b) => {
        // Compare amounts in descending order
        if (Number(b[1]) !== Number(a[1])) {
            return Number(b[1]) - Number(a[1]);
        } else {
            // If amounts are the same, prioritize the deposit that came first
            return Number(a[2]) - Number(b[2]); // Comparing timestamps (third element in each array)
        }
    });

    // Take the top 4 elements from the sorted array
    const top4 = sortedDeposits.slice(0, 4).map(arr => [...arr]); // Create a new array for each element

    return top4;
}



export const useAdminStats = (updater) => {
    let web3 = getWeb3();


    const [stats, setStats] = useState({
        duration : []
});

    const mc = MulticallContractWeb3();
    let stakeContract = new web3.eth.Contract(bscstakeAbi, contract[BSC_CHAIN_ID].STAKE_ADDRESS);



    useEffect(() => {
        const fetch = async () => {
            try {
                const data = await mc.aggregate([
                    stakeContract.methods.Duration(0), //0
                    stakeContract.methods.Duration(1), //1
                    stakeContract.methods.Duration(2), //2
                    stakeContract.methods.Duration(3), //3
                ]);



                setStats({
                    duration : [data[0] , data[1], data[2] , data[3] ],
                })
            }
            catch (err) {
                console.log(err.message);
                toast.error(err.reason)
            }
        }


        fetch();

        // eslint-disable-next-line
    }, [updater]);

    return stats;
}
