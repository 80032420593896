import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { formatPrice } from '../hooks/contractHelper';
import { useAccount } from 'wagmi';
import { useRefStats } from '../hooks/useBscCommon';

export default function Refsection({ accStats }) {
    const { address } = useAccount();
    const [referrallink, setReferrallink] = useState(
        "Please connect your wallet"
    );
    const refStats = useRefStats();
    const [refcopy, setRefcopy] = useState(false);
    let base_url = `${window.location.origin}?ref=`;

    useEffect(() => {
        if (address) {
            setReferrallink(`${base_url}${address}`);
        } else {
            setReferrallink("Please connect your wallet");
        }
    }, [address, base_url]);



    return (
        <div className='ref-main text-center'>
            <article className="presentation">
                <h2 className="page-title text-white">5 Tier Referral Program</h2>
                <h5 className="description text-white">Earn 5% on Level 1</h5>
            </article>
            <div className="input-group mx-3 my-3 d-flex justify-content-center">
                <input type="text" className="ref-input" value={referrallink} readOnly={true} />
                <span className="input-group-text" id="basic-addon2">

                    <CopyToClipboard text={referrallink} onCopy={() => {
                        setRefcopy(true);
                        setTimeout(() => {
                            setRefcopy(false);
                        }, 2000)
                    }}>
                        <button className="button" type="button">
                            <img src="https://s2.svgbox.net/octicons.svg?ic=copy" alt="Icon" />
                        </button>
                    </CopyToClipboard>
                    <span>{refcopy && 'copied'}</span>
                </span>
            </div>
            <div className="d-flex justify-content-center">
                {refStats.l1 &&
                    <div className="ref-per bd-highlight level-span mx-3">Level 1 &nbsp; :&nbsp;  {parseInt(refStats.l1)}%</div>
                }
                {refStats.l2 &&
                    <div className="ref-per bd-highlight level-span mx-3">Level 2 &nbsp; :&nbsp;  {parseInt(refStats.l2)}%</div>
                }
                {refStats.l3 &&
                    <div className="ref-per bd-highlight level-span mx-3">Level 3 &nbsp; :&nbsp;  {parseInt(refStats.l3)}%</div>
                }
                {refStats.l4 &&
                    <div className="ref-per bd-highlight level-span mx-3">Level 4 &nbsp; :&nbsp;  {parseInt(refStats.l4)}%</div>
                }
                {refStats.l5 &&
                    <div className="ref-per bd-highlight level-span mx-3">Level 5 &nbsp; :&nbsp;  {parseInt(refStats.l5)}%</div>
                }
        
            </div>
            <div className="d-flex justify-content-center">
                <div className="ref-per bd-highlight level-span mx-3">Level 1 &nbsp; :&nbsp;  {accStats.refLevelCount && accStats.refLevelCount[0] ? accStats.refLevelCount[0] : 0}</div>
                <div className="ref-per bd-highlight level-span mx-3">Level 2 &nbsp; :&nbsp;  {accStats.refLevelCount && accStats.refLevelCount[1] ? accStats.refLevelCount[1] : 0}</div>
                <div className="ref-per bd-highlight level-span mx-3">Level 3 &nbsp; :&nbsp;  {accStats.refLevelCount && accStats.refLevelCount[2] ? accStats.refLevelCount[2] : 0}</div>
                <div className="ref-per bd-highlight level-span mx-3">Level 4 &nbsp; :&nbsp;  {accStats.refLevelCount && accStats.refLevelCount[3] ? accStats.refLevelCount[3] : 0}</div>
                <div className="ref-per bd-highlight level-span mx-3">Level 5 &nbsp; :&nbsp;  {accStats.refLevelCount && accStats.refLevelCount[4] ? accStats.refLevelCount[4] : 0}</div>
            </div>
            <div className="d-flex justify-content-center">
                <div className="p-2 bd-highlight level-span mt-3 mx-4">Your Referral Earning : {accStats.reward ? formatPrice(accStats.reward) : 0} CHC</div>
                <div className="p-2 bd-highlight level-span mt-3 mx-4">Your Referral Count : {accStats.referredCount ? accStats.referredCount : 0} </div>
            </div>
        </div>
    )
}
